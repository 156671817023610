import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

// Adjust the styles to fit an 80mm thermal printer receipt
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        width: '80mm', // Standard width for the XP-80C printer
        padding: '5mm', // Padding for clarity near the edges
    },
    header: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 5,
    },
    logoContainer: {
        marginBottom: 5,
    },
    logo: {
        width: 80, // Adjusted to fit well within the 80mm width
    },
    address: {
        fontSize: 9,
        textAlign: 'center',
    },
    section: {
        marginVertical: 5,
    },
    item: {
        fontSize: 9,
        textAlign: 'left', // Align text to the left for easier reading on a receipt
    },
    total: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        marginVertical: 5,
    },
    thankYou: {
        fontSize: 11,
        marginTop: 10,
        textAlign: 'center',
    },
    website: {
        fontSize: 10,
        marginTop: 5,
        textAlign: 'center',
    },
    line: {
        marginVertical: 5,
        borderBottomWidth: 1,
        borderBottomColor: '#000',
    },
});

const Receipt = ({ data }) => {
    const { amount, customer_name, pos_items, reference_number, createdAt } = data;

    // Function to calculate the total amount
    const calculateTotal = () => {
        let total = 0;
        pos_items.forEach((item) => {
            const itemAmount = parseFloat(item?.amount);
            if (!isNaN(itemAmount)) {
                total += itemAmount;
            }
        });
        return total.toFixed(2);
    };

    return (
        <Document>
            {/* Adjust the Page size to match the 80mm width of the printer */}
            <Page size={[227.5]} style={styles.page}>

                {/* Header Section */}
                <View style={styles.header}>
                    <View style={styles.logoContainer}>
                        <Image source="https://imagedelivery.net/HbOJcomwWj9PRVewDVmVUw/4601dcab-46ba-4f0a-eca6-3892d2d43300/public" style={styles.logo} />
                    </View>
                    <Text style={styles.address}>
                        Nakenohs Blvd, No 40 Alexander Road, Ikoyi, Lagos, Nigeria
                    </Text>
                    <Text style={styles.address}>+234 906 569 1940, +234 811 624 8821</Text>
                    <Text style={styles.address}>Ref: {reference_number}</Text>
                </View>

                {/* Date Section */}
                <View style={styles.section}>
                    <Text style={styles.item}>Date: {new Date(createdAt).toLocaleString()}</Text>
                </View>

                {/* Items Section */}
                <View style={styles.line}></View>
                {pos_items?.map((item, index) => (
                    <Text key={index} style={styles.item}>
                        {item?.sold_product?.item?.name} - {item?.sold_product?.code} - 1 x {parseFloat(item?.amount).toFixed(2)}
                    </Text>
                ))}
                <View style={styles.line}></View>

                {/* Total Section */}
                <Text style={styles.total}>Total: ₦{calculateTotal()}</Text>

                {/* Thank You Message */}
                <Text style={styles.thankYou}>
                    Thank you for your purchase, {customer_name}! &#9829;
                </Text>
                <Text style={styles.website}>www.femihandbags.com</Text>
            </Page>
        </Document>
    );
};

export default Receipt;
