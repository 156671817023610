import { useState } from 'react';
import axiosClient from '../../axios';
import AddVendorModal from '../vendors/AddVendorModal';
import { toast } from 'react-toastify';

const AddExpenseModal = ({ isOpen, closeModal, vendors = [], expenseCategories = [], onExpenseCreated }) => {
    const [purpose, setPurpose] = useState('');
    const [vendorId, setVendorId] = useState('');
    const [warehouseId, setWarehouseId] = useState('');
    const [staff, setStaff] = useState('');
    const [authorizedBy, setAuthorizedBy] = useState('');
    const [expenseItems, setExpenseItems] = useState([{ amount: '', quantity: '', expenseCategoryId: '' }]);
    const [total, setTotal] = useState(0);
    const [isVendorModalOpen, setIsVendorModalOpen] = useState(false);
    const [errors, setErrors] = useState({});

    const [vendorList, setVendorList] = useState(vendors);

    const closeVendorModal = () => setIsVendorModalOpen(false);

    const handleVendorChange = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue === 'add_new_vendor') {
            setIsVendorModalOpen(true);
        } else {
            setVendorId(selectedValue);
        }
    };

    const handleVendorsCreated = (newVendor) => {
        // Add the new vendor to the vendor list and auto-select it
        const updatedVendors = [...vendorList, newVendor];
        setVendorList(updatedVendors);
        setVendorId(newVendor.id);
        toast.success("Vendor created and selected!");
        closeVendorModal();
    };

    const handleExpenseItemChange = (index, field, value) => {
        const updatedItems = [...expenseItems];
        updatedItems[index][field] = value;
        setExpenseItems(updatedItems);
        calculateTotal(updatedItems);
    };

    const handleAddExpenseItem = () => {
        setExpenseItems([...expenseItems, { amount: '', quantity: '', expenseCategoryId: '' }]);
    };

    const handleRemoveExpenseItem = (index) => {
        const updatedItems = expenseItems.filter((_, i) => i !== index);
        setExpenseItems(updatedItems);
        calculateTotal(updatedItems);
    };

    const calculateTotal = (items) => {
        const totalAmount = items.reduce((acc, item) => {
            const subtotal = parseFloat(item.amount) * parseInt(item.quantity);
            return acc + (isNaN(subtotal) ? 0 : subtotal);
        }, 0);
        setTotal(totalAmount);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!purpose) newErrors.purpose = 'Purpose is required';
        if (!vendorId) newErrors.vendorId = 'Vendor is required';
        if (!staff) newErrors.staff = 'Staff is required';
        if (!authorizedBy) newErrors.authorizedBy = 'Authorized by is required';
        if (expenseItems.some(item => !item.amount || !item.quantity || !item.expenseCategoryId)) {
            newErrors.expenseItems = 'All expense items must be filled';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        const expenseData = {
            purpose,
            vendor_id: vendorId,
            warehouse_id: warehouseId,
            staff,
            authorized_by: authorizedBy,
            expenseItemsArray: expenseItems.map(item => ({
                amount: parseFloat(item.amount),
                quantity: parseInt(item.quantity),
                expense_category_id: item.expenseCategoryId,
            })),
        };

        try {
            const response = await axiosClient.post('/expense/create', expenseData);
            if (response.status === 201) {
                onExpenseCreated();
                // alert('Expense added successfully!');

                // closeModal();
            }
        } catch (error) {
            console.error('Failed to add expense:', error);
        }
    };
   
    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg w-full max-w-lg">
                <h2 className="text-xl font-semibold mb-4">Add Expense</h2>

                {/* Purpose */}
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">Purpose</label>
                    <input
                        type="text"
                        value={purpose}
                        onChange={e => setPurpose(e.target.value)}
                        className={`border p-2 w-full ${errors.purpose ? 'border-red-500' : 'border-gray-300'}`}
                        placeholder="Enter the purpose"
                    />
                    {errors.purpose && <p className="text-red-500 text-sm">{errors.purpose}</p>}
                </div>

                {/* Vendor */}
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">Vendor</label>
                    <select
                        value={vendorId}
                        onChange={e => setVendorId(e.target.value)}
                        className={`border p-2 w-full ${errors.vendorId ? 'border-red-500' : 'border-gray-300'}`}
                    >
                        <option value="">Select Vendor</option>
                        <option value="" > ADD VENDOR</option>
                        {vendors.length > 0 ? vendors.map(vendor => (
                            <option key={vendor.id} value={vendor.id}>{vendor.business_name}</option>
                        )) : <option value="" disabled>No Vendors Available</option>}
                    </select>
                    {errors.vendorId && <p className="text-red-500 text-sm">{errors.vendorId}</p>}
                </div>



                {/* Staff */}
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">Staff</label>
                    <input
                        type="text"
                        value={staff}
                        onChange={e => setStaff(e.target.value)}
                        className={`border p-2 w-full ${errors.staff ? 'border-red-500' : 'border-gray-300'}`}
                        placeholder="Enter staff name"
                    />
                    {errors.staff && <p className="text-red-500 text-sm">{errors.staff}</p>}
                </div>

                {/* Authorized By */}
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">Authorized By</label>
                    <input
                        type="text"
                        value={authorizedBy}
                        onChange={e => setAuthorizedBy(e.target.value)}
                        className={`border p-2 w-full ${errors.authorizedBy ? 'border-red-500' : 'border-gray-300'}`}
                        placeholder="Enter who authorized the expense"
                    />
                    {errors.authorizedBy && <p className="text-red-500 text-sm">{errors.authorizedBy}</p>}
                </div>

                {/* Expense Items */}
                <h3 className="text-lg font-semibold mb-2">Expense Items</h3>
                {expenseItems.map((item, index) => (
                    <div key={index} className="mb-4">
                        <div className="flex space-x-2 items-center">
                            <input
                                type="number"
                                placeholder="Amount"
                                value={item.amount}
                                onChange={e => handleExpenseItemChange(index, 'amount', e.target.value)}
                                className="border p-2 w-full"
                            />
                            <input
                                type="number"
                                placeholder="Quantity"
                                value={item.quantity}
                                onChange={e => handleExpenseItemChange(index, 'quantity', e.target.value)}
                                className="border p-2 w-full"
                            />
                            <select
                                value={item.expenseCategoryId}
                                onChange={e => handleExpenseItemChange(index, 'expenseCategoryId', e.target.value)}
                                className="border p-2 w-full"
                            >
                                <option value="">Select Category</option>
                                {expenseCategories.length > 0 ? expenseCategories.map(category => (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                )) : <option value="" disabled>No Categories Available</option>}
                            </select>
                            <button
                                onClick={() => handleRemoveExpenseItem(index)}
                                className="text-red-500 hover:text-red-700"
                            >
                                Remove
                            </button>
                        </div>
                        <div className="text-sm text-gray-600">
                            Subtotal: {parseFloat(item.amount) * parseInt(item.quantity) || 0}
                        </div>
                    </div>
                ))}
                <button
                    onClick={handleAddExpenseItem}
                    className="mb-4 bg-green-500 text-white p-2 rounded hover:bg-green-600"
                >
                    Add Item
                </button>

                <div className="mb-4">
                    <strong className="text-lg">Total: {total}</strong>
                </div>

                {/* Footer Buttons */}
                <div className="flex justify-end space-x-2">
                    <button
                        onClick={handleSubmit}
                        className={`bg-blue-500 text-white p-2 rounded hover:bg-blue-600 ${Object.keys(errors).length > 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={Object.keys(errors).length > 0}
                    >
                        Submit
                    </button>
                    <button
                        onClick={closeModal}
                        className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600"
                    >
                        Close
                    </button>
                </div>
                <button
                    onClick={closeModal}
                    className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600"
                >
                    Close
                </button>
            </div>
            <AddVendorModal
                isOpen={isVendorModalOpen}
                closeModal={closeVendorModal}
                onVendorsCreated={handleVendorsCreated}
            />
        </div>
    );
};

export default AddExpenseModal;
