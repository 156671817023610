import { useState, useEffect, useCallback } from 'react';
import ExpenseFilter from './ExpenseFilter';
import ExpenseSort from './ExpenseSort';
import Pagination from './Pagination';
import DateFormat from '../Utils/DateFormat';
import { FaEye } from 'react-icons/fa';
import axiosClient from '../../axios';
import { useExpenseContext } from '../../hooks/useExpensesContext';
import { useVendorContext } from '../../hooks/useVendorsContext.js';
import Spinner from '../Spinner';
import { useExpenseCategory } from '../../hooks/useExpenseCategories.js';
import { useWareHousesContext } from '../../hooks/useWareHouseContext.js';
import AddExpenseModal from './AddExpenseModal.jsx';
import { toast } from 'react-toastify';
import MoneyFormat from '../Utils/MoneyFormat.jsx';

const ExpenseList = () => {
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({});
    const [sort, setSort] = useState('monthly');
    const { expenses, dispatch } = useExpenseContext();
    const { vendors, dispatch: set_dispatch } = useVendorContext();
    const { expenseCategories, dispatch: set_expense_categories } = useExpenseCategory();
    const { wareHouses, dispatch: set_warehouses } = useWareHousesContext();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const fetchVendors = useCallback(async () => {
        try {
            const response = await axiosClient.get(`expense/vendors`);
            if (response?.status === 200) {
                set_dispatch({
                    type: 'SET_VENDORS',
                    payload: response.data.data,
                });
            }
        } catch (error) {
            console.error('Failed to fetch vendors:', error);
        }
    }, [set_dispatch]);

    const fetchWareHouses = useCallback(async () => {
        try {
            const response = await axiosClient.get(`warehouse`);

            if (response?.status === 200) {
                set_warehouses({
                    type: 'SET_WARE_HOUSES',
                    payload: response?.data,
                });
            }
        } catch (error) {
            console.error('Failed to fetch warehouses:', error);
        }
    }, [set_warehouses]);

    const fetchExpenseCategories = useCallback(async () => {
        try {
            const response = await axiosClient.get(`expense/categories`);

            if (response?.status === 200) {
                set_expense_categories({
                    type: 'SET_EXPENSE_CATEGORIES',
                    payload: response.data.data,
                });
            }
        } catch (error) {
            console.error('Failed to fetch expense categories:', error);
        }
    }, [set_expense_categories]);

    const fetchExpenses = useCallback(async () => {
        const queryParams = new URLSearchParams({
            sort,
            expense_category_id: filters.expenseCategory || '',
            vendor_id: filters.vendor || '',
            warehouse_id: filters?.warehouse || '',
            page: currentPage,
        });

        try {
            const response = await axiosClient.get(`expense/fetch?${queryParams}`);

            if (response?.status === 200) {
                dispatch({
                    type: 'SET_EXPENSES',
                    payload: response.data.data.data,
                });
                setTotalPages(response.data.data.totalPages);
            }
        } catch (error) {
            console.error('Failed to fetch expenses:', error);
        }
    }, [sort, filters, currentPage, dispatch]);

    useEffect(() => {
        fetchWareHouses();
        fetchExpenseCategories();
        fetchVendors();
        fetchExpenses();
    }, [dispatch, filters, sort, currentPage]);

    if (!expenses) {
        return <Spinner />;
    }

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
        setCurrentPage(1);
    };

    const handleSortChange = (newSort) => {
        setSort(newSort);
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleExpenseCreated = () => {
        toast.success("Expense created!")
        fetchExpenses(); // Refresh the expense data
        closeModal();
    };

    return (
        <div>

            <div className="flex justify-between items-center">
                <ExpenseFilter onFilterChange={handleFilterChange} vendors={vendors} expenseCategories={expenseCategories} warehouses={wareHouses} />
                <ExpenseSort onSortChange={handleSortChange} />
                <button
                    onClick={openModal}
                    className="bg-blue-500 text-white p-2 rounded"
                >
                    Add Expense
                </button>
            </div>

            <div className="flex flex-col mt-4">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full border-collapse border border-gray-300"> {/* Added border-collapse */}
                                <thead className="border-b">
                                    <tr>
                                        {["S/N", "Purpose", "Warehouse", "Details", "Staff", "Total", "Date"].map((header, index) => (
                                            <th key={index} className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-b border-gray-300">{header}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {expenses.map((expense, index) => (
                                        <tr className="border-b border-gray-300" key={expense.id || index}> {/* Added border */}
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-normal">{expense.purpose}</td>
                                            {/* <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{expense.vendor.name}</td> */}
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{expense.warehouse?.name}</td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {expense.items.map((item, itemIndex) => (
                                                    <div key={itemIndex} className="flex flex-col">
                                                        <span><strong>CATEGORY:</strong> {item.category.name}</span>
                                                        <span><strong>QUANTITY:</strong> {item.quantity}</span>
                                                        <span><strong>AMOUNT:</strong><MoneyFormat amount={item?.amount} /> </span>
                                                    </div>
                                                ))}
                                            </td>

                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{expense.staff}</td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"><MoneyFormat amount={expense?.total} /></td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                <DateFormat date={expense.createdAt} />
                                            </td>
                                            {/* <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                <FaEye />
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />

            <AddExpenseModal
                isOpen={isModalOpen}
                closeModal={closeModal}
                vendors={vendors}
                expenseCategories={expenseCategories}
                onExpenseCreated={handleExpenseCreated}
            />

        </div>
    );
};

export default ExpenseList;
